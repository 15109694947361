@import "vars";

.trophies {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.trophy {
  margin-bottom: 4rem;
  border: 1px solid $color-grey-light-2;
  padding: 2rem;
  text-align: center;
  border-radius: 2rem;
  width: 100%;
  max-width: 50rem;
  background: url('../img/bg-footer.png');
  color: $color-white;

  @media screen and (min-width: $lg) {
    margin-right: 4rem;
    width: 30rem;
  }

  &:last-child {
    margin-right: 0;
  }

  p {
    margin-top: .5rem;
  }

  img {
    display: inline-block;
    margin: 3rem auto 1rem auto;
    height: 200px;
  }

  &.wide {
    width: auto;
    margin: 0;
    border-right: 0;

    img {
      height: 300px;
    }
  }
}

.share-row {
  margin: 2rem 0 1rem 0;
}