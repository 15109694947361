@import "vars";

.center-form, .registration-form, .login-form, .invite-form {
  @media screen and (min-width: $lg) {
    width: 70rem;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: 4rem;
  }

  .submit {
    @media screen and (min-width: $lg) {
      margin-left: 25rem;
    }
  }
}

.thanks {
  text-align: center;
}
