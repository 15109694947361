.team-logos {
  display: flex;
  margin-bottom: 1.5rem;

  .logo {
    position: relative;
    height: 5rem;
    overflow: hidden;
    margin-right: 2rem;

    img {
      width: 10rem;
    }

    .btn-danger {
      position: absolute;
      right: .5rem;
      top: .5rem;
      padding: 3px 5px;
      font-size: 0.5em;
    }
  }
}

.files-section {
  display: flex;

  .col {
    flex: 1;
    margin-right: 3rem;

    &:last-child {
      margin-right: 0;
      flex: 2;
    }
  }
}
