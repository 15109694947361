@import "vars";

.btn:disabled {
  background: $color-grey-light-2;
  color: $color-grey-light-3;
  cursor: not-allowed;
}

.btn {
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  cursor: pointer;
}

.btn-sm, .form input.btn-sm {
  padding: .6rem;
  font-size: 1.4rem;
}

.btn-none {
  border: none;
  background: none;
  outline: none;
}
//
//.btn-color-default {
//  color: $color-primary;
//}

.btn-color-danger {
  color: $color-danger;
}

.btn-primary {
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  border-style: none;
  border-radius: 10px;
  background-color: $color-primary;
  box-shadow: 3px 4px 0px 0px #565656;
  margin-right: 4px; /*for shadow*/
  font-family: $font-secondary;
  font-weight: 500;
  color: $color-text;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

.btn-info {
  padding: 1px;
  font-size: 9px;
  width: 20px;
}

.btn-default {
  @extend .btn-primary;
}

.btn-important {
  font-weight: bold;
  font-size: 1.75rem;
}

.btn-danger {
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  border-style: none;
  border-radius: 10px;
  background-color: $color-danger;
  box-shadow: 3px 4px 0px 0px #565656;
  font-family: $font-secondary;
  font-weight: 500;
  color: $color-white;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

.btn-success {
  @extend .btn-primary;
}

.sort-button {
  border: 0;
  padding: 0;
  margin-right: .5rem;
  color: $color-grey-1;
  font-size: 1.3rem;
  background: none;

  &.active {
    color: #000;
  }
}