@import "vars";

.draft-range-selector {
  .range-time {
    margin-top: 1.5rem;
  }

  .draft-range-2 {
    margin-top: 4.5rem;
  }

  @media screen and (min-width: $lg) {
    display: flex;

    .draft-range-1 {
      margin-right: 3rem;
    }

    .draft-range-2 {
      margin-top: 0;
    }

    .range-time {
      text-align: right;
    }
  }
}

.countdown {
  font-weight: bold;
  font-size: 1.8rem;
  margin: 1rem 0;
}

.draft-teams-slider {
  background: $color-grey-light-1;
  padding: 2rem;

  .draftDirection {
    font-weight: bold;
    font-size: 2rem;
    margin-right: 2rem;
  }

  .heading {
    font-weight: bold;
  }

  ul {
    display: grid;
    grid-gap: 1rem 2rem;
    grid-template-columns: repeat(auto-fill, minmax(min-content, 300px));
  }

  li {
    display: flex;
    align-items: center;
    background: $color-primary;
    padding-right: 1rem;
    border-radius: 10px;
    background-color: $color-primary;
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-text;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    overflow: hidden;
    border: 2px solid $color-grey-light-2;

    .teamName {
      flex: 1;
      padding: 0 1rem;
    }

    .draftOrder {
      font-size: 20px;
      font-weight: bold;
      padding: 10px;
      background: $color-grey-light-2;
      display: block;
      border-radius: 10px;
    }


    &.past {
      opacity: 0.3;
    }

    &.selected {
      border-color: black;
      background-color: $color-danger;
      color: $color-white;

      .draftOrder {
        //background: $color-success;
        color: $color-text;
        //font-weight: bold;
      }
    }
  }
}

.draft-picks-section {
  margin-top: 5rem;
  margin-bottom: 5rem;

  width: auto;
  max-width: 89vw;
  overflow: scroll;
  text-align: left;
  @media screen and (min-width: $lg) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .draft-picks-table {
    font-size: 1.4rem;
    width: auto;
    @media screen and (min-width: $lg) {
      margin: auto;
    }

    .player-cell-highlighted {
      background: #32CD32;
      font-weight: bold;
    }
  }
}

.league-draft-overview-section, .my-team-section {
  margin-bottom: 3rem;
  max-width: 100vw;

  @media screen and (max-width: $lg) {
    overflow: hidden;
    max-width: 89vw;
  }
}