@import "vars";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}


.draft-modal-overlay, .tall-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  width: 100vw;
  height: 100vh;

  @media screen and (min-width: $lg) {
    display: initial;
    width: auto;
    height: auto;
  }
}

.draft-modal, .default-modal, .player-popup {
  position: relative;
  display: inline-block;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  min-height: 20rem;
  width: 85vw;
  max-width: 60rem;
  padding: 3rem;
  background: #fff;
  border-radius: .6rem;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  outline: none;
  overflow: auto;
  color: $color-grey-1;

  @media screen and (min-width: $lg) {
    overflow: hidden;
    padding: 3rem 3rem 8rem 3rem;
  }

  &.autofit {
    width: auto;
    max-width: initial;
  }

  &.wide {
    width: auto;
    max-width: 90%;
  }

  .modal-title {
    font-size: 2.3rem;
    margin-bottom: 2rem;
  }

  .modal-content {
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 3rem 0;
  }

  .modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    font-size: 2rem;
    color: $color-grey-light-2;
    cursor: pointer;
  }

  .modal-actions {
    margin: 6rem 0 6rem 0;

    > * {
      min-width: 10rem;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
    text-align: center;

    @media screen and (min-width: $lg) {
      margin: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 2rem;
      text-align: right;
    }
  }
}


.message-modal {
  .modal-content {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.message-preview-modal {
  .modal-content {
    height: 70vh;
    width: 90vw;
    overflow-y: auto;
  }
}

.player-popup {
  color: #fff;
  background: $color-danger;
  opacity: 0.8;

  .modal-close {
    color: #fff;
  }

  .table {
    color: $color-text;
  }
}

//Disable scroll when modal is open
.ReactModal__Body--open {
  overflow: hidden;
}

//Transition effect
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  z-index: 1000;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

//Fore React-Select
.Select-menu-outer {
  border: 2px solid red;
  z-index: 999 !important;
}




//.ReactModal__Html--open,
//.ReactModal__Body--open {
//  overflow: hidden; /* prevents background page from scrolling when the modal is open */
//}
//
//.ReactModal__Overlay {
//  position: fixed;
//  z-index: 999999;
//  top: 0;
//  left: 0;
//  width: 100vw;
//  height: 100vh;
//  //background: rgba(0, 0, 0, 0.5);
//  display: flex;
//  //align-items: center;
//  //justify-content: center;
//}

.ReactModal__Content {
  //max-width: calc(100vw - 2rem);
  //max-height: calc(100vh - 2rem);
  //height: auto;
  //background: white;
  //width: 50rem;
  //height: 50rem;
  //max-width: calc(100vw - 2rem);
  //max-height: calc(100vh - 2rem);
  //box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  //overflow: auto;
  //position: relative;
}
//
//.modal-close-btn {
//  cursor: pointer;
//  top: 1.5rem;
//  right: 1.5rem;
//  position: absolute;
//  width: 3rem;
//  height: 3rem;
//}