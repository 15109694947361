@import "vars";


.h-large {
  text-transform: uppercase;
  margin: 2rem 0;
  font-family: 'dalmation_edited', sans-serif;
  font-weight: normal;
  font-size: 4.2rem;
  line-height: 4.5rem;
  letter-spacing: 2px;

  @media screen and (min-width: $lg) {
    font-size: 5.3rem;
    line-height: 6rem;
  }
}

.welcome-banner {
  display: flex;
  justify-content: space-between;
  background: $color-grey-1;
  color: $color-white;
  background-image: url("/img/background-landing.png");
  background-position: bottom center;
  background-size: cover;
  padding: 10px 30px;
  margin: 0;
  font-size: 1.8rem;
  min-height: 65rem;
  min-width: 93rem;
  overflow: hidden;

  @media (max-width: $lg) {
    flex-direction: column;
    align-items: center;
    background: url("/img/bg-landing-mobile.png") $color-grey-1 0 0 no-repeat;
    background-size: 106%;
    background-position: 0% 32%;
    margin: 4rem 0 0 0;
    padding: 4rem 1rem 1em 1rem;
    min-height: auto;
    min-width: auto;
  }

  @media (max-width: 460px) {
    background-size: 110%;
    background-position: top right;
    background-position-y: 20%;

  }

  @media (max-width: 360px) {
    background-size: 133%;
    background-position: top right;
    background-position-y: 20%;

  }

  .banner {
    display: none;

    @media screen and (min-width: $lg) {
      display: block;
    }
  }

  h1 {
    @extend .h-large;
  }

  .left-section {
    max-width: 30rem;
    z-index: 2;
    margin: 60px 0 0;

    @media screen and (max-width: $lg) {
      margin: 0;

      h1 {
        margin: 0;
      }

      // position: absolute;
      // left: 2rem;
      // top: 6.5rem;
    }
  }

  .center-section {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    color: white;
    padding: 2rem 0 2rem 0;
    margin-top: 4rem;
    // transform: translate(-50%, -50%);
    margin: auto auto 105px;
    z-index: 1;

    .actions {
      text-align: center;
      margin: 3.5rem;
      margin: 0px 30px 20px;

      .btn {
        width: 100%
      }

      p {
        margin: 2.5rem 10px 0;

        @media screen and (max-width: $lg) {
          margin-top: 1.5rem;
        }
      }
    }

    a.link {
      color: $color-white;
    }

    h3 {
      display: block;
      width: 50%;
      margin: 2rem auto;
      font-size: 1.8rem;
    }

    .logo {
      position: absolute;
      display: block;
      height: 12rem;
      top: 0;
      left: 50%;
      transform: translate(-50%, -75%);
    }

    @media screen and (max-width: $lg) {
      margin: 100px auto 70px;
      max-width: 320px;
      width: 100%;

      p {
        max-width: 100%;
      }

      h3 {
        display: none;
      }

      .actions {
        margin: 20px 10px;
      }

      .players-bg-block {
        .fog-bg {
          display: none;
        }
      }
    }

    // @media (max-width: 600px) {
    //   width: auto;
    // }

    // Light lines
    $outside-step: -3px;
    $weight: 5px;

    .light-line {
      position: absolute;
      background: radial-gradient(circle, rgba(255, 255, 255, 1) -30%, rgba(128, 128, 128, 0) 101%);
      z-index: -1;
      filter: blur(1.5px);
    }

    .light-line-h {
      height: $weight;
      left: 50%;
      transform: translate(-50%, 0);
      width: 140%;
    }

    .light-line-v {
      height: 140%;
      width: $weight;
      top: 50%;
      transform: translate(0, -50%);
    }

    .light-lines-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .light-lines-h-container {
      @extend .light-lines-container;
      background: transparentize(#808080, 0.4);

      &::after {
        @extend .light-line;
        @extend .light-line-h;
        content: '';
        top: $outside-step;
      }

      &::before {
        @extend .light-line;
        @extend .light-line-h;
        content: '';
        bottom: $outside-step;
      }
    }

    .light-lines-v-container {
      @extend .light-lines-container;

      &::after {
        @extend .light-line;
        @extend .light-line-v;
        content: '';
        left: $outside-step;
      }

      &::before {
        @extend .light-line;
        @extend .light-line-v;
        content: '';
        right: $outside-step;
      }

    }

    .players-bg-block {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -58%);
      width: 220%;
      height: 200%;
      z-index: -2;

      .players-bg,
      .fog-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .players-bg {
        background-image: url('/img/welcome_players.png');
      }

      .fog-bg {
        background-image: url('/img/welcome_fog.png');
      }

      @media screen and (max-width: $lg) {
        display: none;
        // NEXT STYLES FOR MOBILE VERSION OF PLAYERS
        height: 150%;
        transform: translate(-50%, -50%);

        .players-bg {
          -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 98%, transparent 100%);
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 98%, transparent 100%);
          background-image: url('/img/welcome_players_mobile.png');
          background-position: bottom;
        }
      }


    }
  }

  .right-section {
    text-align: right;
    font-size: 1.8rem;
    margin: auto 0 30px 0;
    z-index: 2;

    h1 {
      font-family: 'dalmation_edited', sans-serif;
    }

    p {
      max-width: 30rem;
      line-height: 2.5rem;
      display: inline-block;
    }

  }

  //.create-or-join {
  //  font-family: $font-secondary;
  //  margin: 5rem 0;
  //  text-align: center;
  //
  //  span {
  //    padding: 0 1rem;
  //  }
  //}


}



/*

1) https://prnt.sc/DXlib4PAoMpg - Хорошо внизу, плохо вверху
2) https://prnt.sc/Jkry0wRVCnXa - Хорошо вверху, плохо внизу
3)




*/