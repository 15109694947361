*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

ul {
  list-style: none;
}

ol {
  list-style-position: inside;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}
