@import "reset";
@import "breakpoints";
@import "fonts";
@import "typo";
@import "forms";
@import "buttons";
@import "layout";
@import "notifications";
@import "tables";
@import "roster-tables";
@import "modals";
@import "leagues";
@import "players";
@import "trophies";
@import "drafts";
@import "game-center";
@import "dashboard";
@import "profile-settings";
@import "user-notifications";
@import "timepicker";
@import "season";
@import "scores-config";
@import "rosters";
@import "files";
@import "blog";
@import "animate";

//components
@import "header";
@import "content";
@import "footer";
@import "blog-posts";
@import "slider";
@import "team-slider";
@import "slider-form-widget";
@import "owner";

//pages
@import "homepage";
@import "registration";


h1,
h2 {
    font-family: 'dalmation_edited', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
}