@import "vars";

.container {
  @media screen and (min-width: $lg) {
    display: grid;
    grid-template-rows: 6rem auto min-content;
  }
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  background: url('../img/bg.png') 0 0 repeat-y #fff;
  background-size: 100% 100vh;

  margin-top: 6rem; //for header
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100vw;
  padding: 3rem 0;

  @media screen and (min-width: $lg) {
    margin-top: 0;
    //background-repeat: no-repeat;
    //background-size: cover;
    overflow: initial;
    height: initial;
  }
}

.content {
  //display: inline-block;
  margin: 0 3rem;

  @media screen and (min-width: $lg) {
    margin: 0 3rem;
    overflow-x: auto;
  }

  &.wide {
    grid-template-columns: auto;
  }

  .top-banner {
    overflow: hidden;
    grid-column: 1 / -1;
    color: #D3D3D3;
    font-family: $font-secondary;
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 5rem;

    > * {
      flex: 1;
    }

    img {
      width: 100%;
    }
  }

  .side-banner {
    display: none;

    @media screen and (min-width: $lg) {
      overflow: hidden;
      display: flex;
      align-items: center;
      text-align: center;
      font-family: $font-secondary;
      color: #D3D3D3;
      font-size: 1.4rem;
      margin-left: 4rem;

      > * {
        flex: 1;
      }

      img {
        height: 100%;
      }
    }
  }

  main {
    flex: 1;
    grid-column: 1;
    font-size: 1.6rem;
    min-height: 50rem;
  }

  h1 {
    margin-bottom: 2rem;
  }

  aside {
    grid-column: 2;
  }
}

.mt-large {
  margin-top: 4.5rem;
}

.mt-default {
  margin-top: 3rem;
}

.mt-small {
  margin-top: 1.5rem;
}

.mb-default {
  margin-bottom: 3rem;
}

.ml-default {
  margin-left: 3rem;
}

.ml-small {
  margin-left: 1.5rem;
}

.mr-default {
  margin-right: 3rem;
}

.mb-small {
  margin-bottom: 1.5rem;
}

.mr-small {
  margin-right: 1.5rem;
}

.ml-tiny {
  margin-left: 0.5rem;
}

.mt-tiny {
  margin-top: 0.5rem;
}

.mb-tiny {
  margin-top: 0.5rem;
}

.mr-tiny {
  margin-right: 0.5rem;
}

.float-right
{
  float: right;
}

.clearfix {
  clear: both;
  overflow: hidden;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.switch-label {
  display: flex;
  align-items: center;
  font-weight: bold;

  span {
    margin-left: 1rem;
    font-size: 1.8rem;
  }
}