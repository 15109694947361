.game-stats-page input:disabled {
  border: none;
}

.roster-tables {
  .table:not(:last-child) {
    margin-bottom: 1rem;
  }

  .roster-header {
    display: flex;

    .table-caption {
      padding: 10px;
      background-color: silver;
    }

    .basic-multi-select {
      width: 100%;
    }
  }
}

.roster-wrapper {
  max-height: 500px;
  overflow: scroll;
  border: 1px solid silver;
  padding: 1rem;
  margin-top: 1.5rem;
}