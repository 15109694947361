@font-face {
  font-family: 'a_love_of_thunderregular';
  src: url('../fonts/a_love_of_thunder-webfont/a_love_of_thunder-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dalmation';
  src: url('../fonts/dalmation-webfont/dalmation-webfont.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dalmation_edited';
  src: url('../fonts/dalmation-edited/dalmation-edited.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}