@import "vars";

.games-slider {
  background: $color-grey-light-1;
  padding: 2rem;

  .team {
    display: flex;
    align-items: center;
    img {
      margin-right: 1rem;
    }
  }

  .heading {
    font-weight: bold;
  }

  li {
    cursor: pointer;
    display: inline-block;
    border: 1px solid $color-grey-light-2;
    padding: 1rem 2rem;
    text-align: center;
    margin-bottom: 2rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    .vs {
      font-size: small;
      color: $color-grey-1;
      margin: 0.5rem 0;
    }

    &.selected {
      font-weight: bold;
      background: $color-success;
      color: $color-white;

      .vs {
        color: $color-grey-light-2;
      }
    }
  }
}

.week-switcher {
  margin: 2rem 0;

  li {
    display: inline-block;

      margin-right: 2rem;

    .btn.selected {
      font-weight: bold;
    }
  }
}

.game-section {
  overflow-x: auto;
  min-height: 0.01%;

  @media screen and (min-width: $lg) {
    width: 100%;
  }

  display: flex;
  .team-column {
    flex: 1;
    border: 1px solid $color-grey-light-2;
    padding: 1.5rem;
    &:not(:last-child) {
      margin-right: 3rem;
    }

    .team-basics {
      clear:both;
      overflow: hidden;
      margin-bottom: 1rem;
    }

    .team-logo {
      float: left;
      margin-right: 1rem;
    }

    .team-name {
      font-weight: bold;
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .team-owner-name {
      margin-bottom: .5rem;
    }

    .team-owner-rank {
      margin-bottom: 3rem;
    }
  }
}
