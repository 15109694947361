@import "vars";

.table-responsive {
  overflow: auto;
  min-height: 0.01%;

  @media screen and (min-width: $lg) {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.6rem;

  &.selectable tbody tr {
    cursor: pointer;
  }

  th.min-width {
    width: 1px;
  }

  caption {
    text-align: left;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    background: $color-grey-light-2;
    width: max-content;
    //font-size: 112.5%;
  }

  th, td {
    text-align: left;
    border-bottom: 1px solid #f1ecec;
    padding: 1.5rem 1rem;
    font-family: $font-secondary;

    &.text-center {
      text-align: center;
    }
  }

  &.table-sortable {
    th {
      white-space: nowrap;
      font-size: 1.3rem;
    }
  }

  td.min-content {
    width: 1px;
    white-space: nowrap;
  }

  td.nowrap {
    white-space: nowrap;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }

  tr:nth-child(even) {
    background-color: lighten($color-grey-light-2, 19);
  }

  th {
    background: $color-grey-light-1;
  }

  tr.selected {
    background-color: $color-success;
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-white;
  }

  &.table-bordered {
    th, td {
      border: 1px solid #f1ecec;
    }

    caption {
      border: 1px solid #f1ecec;
      border-bottom: none;
    }
  }

  &.table-condensed {
    font-size: 1.4rem;

    caption {
      padding: 1rem;
    }

    th, td {
      padding: 0.75rem 0.5rem;
    }

    td.min-content {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.roster-actions {
  display: flex;
  white-space: nowrap;
  .btn-ico {
    font-weight: bold;
    padding: 0;

    &:not(:last-child) {
      margin-right: .5rem;
    }

    span {
      vertical-align: middle;
    }

    height: 2.6rem;
    line-height: 2.6rem;
    width: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.standings-table {
  tr.current td {
    background: $color-danger;
    color: #fff;
  }
}