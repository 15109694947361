@import "vars";
.blog-post {
  max-width: 110rem;
  font-size: 1.8rem;
  line-height: 2.5rem;

  .blog-post-title {
    text-decoration: none;
    color: $color-text;
    font-size: 2rem;
    font-weight: bold;
  }

  .date {
    color: $color-grey-1;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}
