@import "vars";

.profile-settings {
  @media screen and (min-width: $lg) {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 15rem;
  }

  margin-top: 2rem;

  h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}
