$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.lg-up {
  display: none;
  @media screen and (min-width: $lg) {
    display: block;
  }
}

.lg-down {
  display: none;
  @media screen and (max-width: ($lg - 1)) {
    display: block;
  }
}