@import "vars";

html {
  font-family: $font-primary;
}

h1 {
  font-family: $font-secondary;
  font-size: $font-size-h1;
}

h2 {
  font-family: $font-secondary;
  font-size: $font-size-h2;
}

h3 {
  font-family: $font-secondary;
  font-size: $font-size-h3;
}

a.link {
  &:link, &:visited, &:hover, &:active {
    color: $color-primary;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-center-mobile {
  text-align: center;
  @media screen and (min-width: $lg) {
    text-align: inherit;
  }
}

main {
  font-family: $font-secondary;
  font-size: 1.6rem;
}

.dl-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem 2rem;

  dt {
    font-weight: bold;
  }
}

.list-normal {
  li {
    margin-bottom: 1rem;
  }
}
