@import "vars";

.language-switcher {
  display: flex;
  justify-content: flex-end;

  .language-switcher-select {
    flex: 1;
  }

  .add-btn {
    margin-left: 1.5rem;
    font-weight: bold;
    height: 100%;
  }
}

table tr.pending-member {
  color: $color-grey-light-3;
}

table tr.open-slot {
  color: $color-grey-1;
}

.draft-section {
  border-radius: 10px;
  background: url('../img/bg-red.png') 0 0;
  background-size: cover;
  border: 1px solid $color-grey-light-2;
  padding: 1.5rem;
  text-align: center;
  color: #fff;

  max-width: 89vw;
  @media screen and (min-width: $lg) {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .btn {
    padding: 1.5rem 6rem;
  }
}
