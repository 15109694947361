@import "vars";

.top-side-actions {
  margin: 2rem 0;

  @media screen and (min-width: $lg) {
    float: right;
    width: 30rem;
    margin: 0;
  }
}

.league-switcher, .season-switcher {
  @media screen and (min-width: $lg) {
    float: right;
    width: 30rem;
  }
}

.team-logo-selector {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  .team-logo-item {
    margin-right: 1rem
  }
}