.blog-posts {
  .blog-post {
    .header-image img {
      max-width: 400px;
      width: 100%;
      margin: 1.5rem 0;
    }
  }
}

.blog-post {
  overflow-wrap: break-word;

  .blog-post-header-image {
    width: 100%;
    max-width: 650px;
    margin: 1.5rem 0;
  }
}

//Featured
.featured-blog-section {
  padding: 3rem;
  background: url('../img/bg.png') 0 0 repeat-y #fff;
  background-size: 100% 100vh;

  .featured-blog-title {
    margin-bottom: 2rem;
    @extend .h-large;
    font-family: 'dalmation_edited', sans-serif;
  }

  .featured-blog-posts {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto;

    @media screen and (min-width: $md) {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: $xl) {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @mixin chess-pattern($color1, $color2) {
      @include blog-post-background($color1);

      @media screen and (max-width: $md - 1) {

        //even-odd pattern
        &:nth-child(even) {
          @include blog-post-background($color2);
        }
      }

      @media screen and (min-width: $md) and (max-width: $xl - 1) {
        @include blog-post-background($color1);

        &:nth-child(4n-2),
        &:nth-child(4n-1) {
          //2 column chess pattern
          @include blog-post-background($color2);
        }
      }

      @media screen and (min-width: $xl) {
        //4 column chess pattern
        @include blog-post-background($color2);

        &:nth-child(8n),
        &:nth-child(8n-2),
        &:nth-child(8n-5),
        &:nth-child(8n-7) {
          @include blog-post-background($color1);
        }
      }
    }

    @mixin blog-post-background($color) {
      background-position: bottom right;
      //background-size: 100% auto;
      background-size: 100% 100%;

      @if $color =='red' {
        background-image: url('../img/bg-red.png');
        color: #fff;
      }

      @else {
        background-image: url('../img/bg-grey.png');
        color: #000;
      }
    }

    @mixin blog-post {
      @include chess-pattern('red', 'grey');
      text-decoration: none;
      padding: 1rem;

      .featured-blog-post-header-image {
        width: 100%;
        max-height: 30rem;
        overflow: hidden;

        &-none {
          display: none;
        }

        .img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .featured-blog-post-heading {}

      .featured-blog-post-date {
        font-size: 1.2rem;
        margin: .3rem 0;
      }

      .featured-blog-post-title {
        font-size: 1.6rem;
      }
    }

    .featured-blog-post-primary {
      @include blog-post;
    }

    .featured-blog-post-secondary {
      @include blog-post;

      .featured-blog-post-heading {
        display: flex;
        flex-direction: column-reverse;
      }

      .featured-blog-post-header-image {
        float: left;
        width: 13rem;
        height: 9rem;
        margin-right: 1rem;
      }
    }

    .featured-blog-post-more {
      @include blog-post;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}