@import 'vars';

a {
  color: $color-danger;
}

body {
  color: $color-text;
}

.message-danger {
  color: $color-danger;
}

.type-info {
  background: $color-danger !important;
}


*[data-badge]:after {
  content: attr(data-badge);
  position: absolute;

  top: -5px;
  right: -5px;
  font-size: 1.2rem !important;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
  font-family: Helvetica Neue, Helvetica, Arial;
}

.content-page {
  line-height: 3rem;
  text-align: justify;

  h1 {
    text-align: left;
    line-height: 4.5rem;
  }

  h3 {
    font-size: 2.4rem;
    margin: 5rem 0 2rem 0;
  }

  ol, ul {
    margin: 3rem 0;

    li {
      margin: 1rem 0;
    }
  }

  a {
    font-weight: bold;
    color: #000;
  }

  .table {
    margin: 2rem 0;
  }
}


.faq {
  @media screen and (min-width: $lg) {
    max-width: 100rem;
  }

  .faq-icon {
    width: 30px;
    height: 30px;
    top: 8px;
    position: relative;
    margin: 0 3px;
  }
}

.hierarchy ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;

  h2 {
    margin: 0;
    font-size: 1.8rem;
  }

  h3 {
    margin: 0;
    font-size: 1.6rem;
  }

  & > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  &> li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
    font-weight: bold;
    font-size: 1.8rem;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
    font-weight: bold;
    font-size: 1.6rem;
  }
}

.social-icons {
  margin: 1rem 0;
  a {
    margin-right: .5rem;
  }
}
