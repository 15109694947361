$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #ccc;
$color-grey-light-3: #8B8B8B;
$color-grey-1: #636363;

$font-serif: serif;
$font-sans-serif: 'Lato', sans-serif;

$font-size-h1: 3.5rem;
$font-size-h2: 3rem;
$font-size-h3: 2.5rem;

$font-primary: $font-sans-serif;
$font-secondary: $font-sans-serif;

$color-primary: #ffcc00;

$color-danger: #de0f16;
$color-success: #02AC1E;

$color-text: #000;
$color-white: #f3edff;

$text-size-standard: 1.6rem;

