@import "vars";

.form-season {

  .error-message {
    margin-left: 0;
  }

  .week-section {
    margin-bottom: 4rem;

    .week-heading {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $color-grey-light-2;

      .btn {
        margin-right: 1rem;
      }
    }

    .week-games {
      margin: 2rem 2rem 2rem 8rem;
    }
  }

  .round-games
  {
    max-width: 120rem;

    .round-col {
      flex: 0;
      margin-right: 2rem;
    }
  }

  .game-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    > div {
      flex: 1;
    }

    .game-heading {
      flex: none;
      margin-top: 1rem;
    }

    .btn {
      margin-top: .5rem;
    }

    .date-col {
      flex: none;
      margin-right: 1rem;
    }

    .time-col {
      flex: none;
      margin-right: 1rem;
    }

    .DateInput__disabled, .SingleDatePickerInput {
      background: none;
    }

    .DateInput_input {
      color: #000;
      font-style: normal;
      padding: 6px;
      flex: none;
      margin-right: 1.5rem;

      &:disabled {
        color: #999;
        border-color: #e6e6e6;
      }
    }
  }
}

.off-round {
  color: #ccc;
  font-weight: bold;
}