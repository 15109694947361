@import 'vars';

.form {
  text-align: center;

  @media screen and (min-width: $lg) {
    text-align: left;
  }

  input:not([type='file']), textarea {
    padding: 1rem;
    font-size: 1.6rem;
    border-radius: 4px;
    border: 1px solid $color-grey-light-2;

    &:focus {
      border-color: $color-primary;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
      outline: none;
    }

    &:disabled {
      border-color: #e6e6e6;
    }
  }

  input.has-error {
    border-color: $color-danger;
    color: $color-danger;
  }

  .form-group {
    text-align: left;
    margin-bottom: 2rem;

    @media screen and (min-width: $lg) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      align-items: baseline;
    }
  }

  .form-label {
    display: block;
    font-family: $font-secondary;
    margin-bottom: .5rem;

    @media screen and (min-width: $lg) {
      flex-basis: 25rem;
      margin-bottom: 0;
    }
  }

  &.form-vertical {
    .form-group {
      display: block;
    }

    .error-message {
      margin: 0;
    }
  }

  .form-control {
    width: 100%;
    @media screen and (min-width: $lg) {
      width: auto;
      flex: 1;
      //flex-grow: 1;
    }
  }

  .form-control.full-width {
    width: 100%;
  }

  input.form-control-min {
    padding: .5rem;
    width: 4rem;
    font-size: 1.4rem;
    border-radius: 4px;
  }

  input.form-control-md {
    width: 6rem;
  }

  .error-message{
    color: red;
    font-family: $font-secondary;
    margin: .5rem 0;

    @media screen and (min-width: $lg) {
      margin: .5rem 0 2rem 25rem;
    }
  }
}

.game-form .error-message {
  margin: 0;
}

.players-search, .search-form {
  @media screen and (min-width: $lg) {
    display: flex;

    .form-group:not(:last-child) {
      flex: 1;
      margin-right: 1.5rem;
    }
  }

  .form-label {
    font-weight: bold;
    margin-right: 3rem;
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    text-align: left;
  }

  .form-control {
    flex: none;
    width: 100%;
  }
}

.dropdown-date-picker {
  display: flex;
  column-gap: 10px;

  .day-picker, .month-picker, .year-picker {
    flex: 1;
  }
}