@import "vars";
@import "breakpoints";

.header {
  height: 6rem;
  align-items: center;
  background: url('../img/repeat.png');
  display: flex;
  padding: 1rem 3rem 1rem 0.5rem;

  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1000;
  max-width: 100vw;
  overflow: hidden;

  @media screen and (min-width: $lg) {
    position: inherit;
    width: auto;
    top: initial;
    z-index: initial;
  }

  .topnav {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-y: scroll;
    @media screen and (min-width: $lg) {
      overflow: initial;
    }
  }

  .logo-box {
      margin-right: 3rem;
  }

  .mobile-nav-toggle {
    display: none;
  }
}

.main-nav-bar {
  flex: 1;

  .nav-links {
    display: none;

    li {
      white-space: nowrap;
      margin-right: 3rem;
    }

    @media screen and (min-width: $md) {
      display: flex;
    }
  }
}

.noscroll {
  overflow: hidden;
  .container {
    width: 100vw;
    overflow: hidden;
  }
}

@media screen and (max-width: $md) {
  .header {
    .mobile-nav-toggle {
      display: block;
      cursor: pointer;
      border: none;
      background: none;
      font-size: 3.5rem;
      position: absolute;
      top: .5rem;
      right: 1.5rem;
      color: #fff !important;
      padding: 0;
      vertical-align: top;
      outline: none;

      &[data-badge]:after {
        top: 5px;
        right: -10px;
      }

      img {
        transform: scale(1.3, .9);
      }
    }
  }

  .header.menu-open {
    .topnav {
      display: block;
      position: fixed;
      left: 0;
      top: 6rem;
      right: 0;
      bottom: 0;
      z-index: 10000;
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../img/bg-landing-mobile.png') 0 0 no-repeat;
      background-size: cover;

      .nav-actions {
        display: block;
        border-top: 2px solid rgba(#fff, 0.3);
      }

      //show langs in nav-links
      .nav-links.langs {
        margin: 1.5rem 0;
        li {
          border: none !important;
          display: inline;
          margin: 0 1rem;
        }
      }

      //hide langs in nav-actions
      .nav-actions .nav-links.langs {
        display: none;
      }

      .nav-links, .nav-actions .nav-links {
        display: block;
        text-align: right;

        li {
          &:not(:last-child) {
            border-bottom: 2px solid rgba(#fff, 0.3);
          }

          padding: .8rem 0;
          margin: 0 1.5rem;
        }

        a {
          font-size: 3.2rem;
        }

      }
    }
  }
}

.nav-links {
  display: none;
  align-items: center;
  a {
    font-family: $font-secondary;
    font-size: 1.4rem;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  .lang {
    cursor: pointer;
  }
}

.nav-actions {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  .nav-links {
    display: none;
    margin-left: 2rem;

    li:not(:last-child) {
      margin-right: 2rem;
    }

    &.extras {
      li:not(:last-child) {
        margin-right: .7rem;
      }

      li:last-child {
        margin-left: 1.5rem;
      }
    }

    @media screen and (min-width: $md) {
      display: flex;
    }
  }
}
