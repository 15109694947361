.rosters-section {
  display: flex;

  .col {
    flex: 1;
    margin-right: 3rem;

    &:last-child {
      margin-right: 0;
      flex: 2;
    }
  }
}

.form-player {
  width: 60rem;
  max-height: 80vh;
  overflow: auto;
}
