@import "vars";

.time-picker {
  position: relative;
  font-size: 1.6rem;
  display: inline-block;

  &__trigger {
    position: relative;
  }

  &__input {
    height: 38px;
    width: 10rem;
    padding: 1rem;
    font-size: 1.6rem;
    border-radius: 4px;
    border: 1px solid $color-grey-light-2;

    &:disabled {
      background: #f2f2f2;
      color: #999;
      &::placeholder {
        color: #999;
      }
    }

    &:focus {
      border-color: $color-primary;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
      outline: none;
    }
  }

  &__suggestion-list {
    background: #fff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    outline: 0;
    z-index: 2099;
    margin: 0;
    font-weight: 400;
    line-height: 1.42857;
    text-align: left;
    overflow-y: auto;
    height: 150px;
    width: 6.5em;
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 1.4rem;
  }

  &__suggestion {
    font-size: inherit;
    display: block;
    width: 100%;
    text-align: left;
    padding: .5rem 1rem;
    cursor: pointer;
    white-space: nowrap;
    color: #000;
    list-style: none;
    margin: 0;
    user-select: none;
    border: none;
    background: 0 0;

    &_selected, &:hover, &:focus {
      background: #1980EC;
      color: #fff;
    }
  }
}



