@import "vars";

.player-popout
{
  max-width: 45rem;
}

.player-popout table
{
  color: black !important;

  tr {
    background-color: $color-grey-light-1;
  }
}

.player-popout-analysis {
  max-height: 15.5rem;
  overflow: scroll;
}