@import "vars";

.footer {
  background: url("/img/bg-footer.png") $color-grey-1;
  padding: 1rem;
  color: $color-white;
  font-size: $text-size-standard;
  font-family: $font-secondary;

  .footer-heading {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: $lg) {
    padding: 1rem 3rem 3rem 3rem;
    .footer-content {
      display: flex;

      > * {
        flex: 1;
      }
    }
  }

  .copy {
    text-align: center;
    margin: 1.5rem 0 3rem 0;
  }

  .partners {
    flex: 1;
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin: .5rem;
    }
  }

  .social {
    .social-icons {
      text-align: center;
      *:not(:last-child) {
        margin-right: 1rem;
      }

      @media screen and (min-width: $lg) {
        display: flex;
      }
    }
  }

  .footer-info {
    @media screen and (min-width: $lg) {
      border-left: 2px solid $color-white;
      padding-left: 2rem;
    }
  }

  .links {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;

    li {
      margin: .5rem 1.5rem .5rem 0;
    }

    a {
      color: $color-white;
      text-decoration: none;
    }

    @media screen and (min-width: $lg) {
      text-align: right;
      display: initial;

      li {
        margin-right: 0;
      }

      li:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .creator {
    margin-top: 2rem;
    text-align: center;

    div {
      display: inline;
      margin-right: 1rem;

      @media screen and (min-width: $lg) {
        display: block;
      }
    }

    @media screen and (min-width: $lg) {
      text-align: left;
    }
  }
}
