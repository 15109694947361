.notification-container-bottom-center, .notification-container-bottom-left, .notification-container-bottom-right, .notification-container-center, .notification-container-top-center, .notification-container-top-left, .notification-container-top-right {
  width: auto !important;
  max-width: 80vw;
}

.notification-title {
  font-size: 1.6rem !important;
}

.notification-message {
  font-size: 1.6rem !important;
}

.notification-item .notification-content {
  padding: 15px 40px 15px 20px !important;
  min-width: 30vw !important;
}

.notification-item .notification-close {
  width: 22px !important;
  height: 22px !important;
  font-size: 1.6rem !important;
}
