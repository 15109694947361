@import "vars";

.score-attribute {
  border: 1px solid $color-grey-light-2;
  //padding: 0 1rem 1rem 1rem;
  margin: 0 2rem 0 0;

  .score-attribute {
    float: left;
    margin: .5rem;
  }

  .score-attribute-heading {
    font-weight: bold;
    background-color: $color-grey-light-1;
    color: $color-grey-1;
    padding: .5rem;
    margin-bottom: .5rem;
  }

  .score-attribute-body {
    padding: .5rem;
    font-size: .9em;

    thead th {
      padding-bottom: .5rem;
    }

    input {
      padding: 0 .3rem;
      font-size: .9em;
    }

    select {
    }
  }

  input {
    width: 50px;
    padding: 0;
  }
}
